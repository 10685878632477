export enum DestinationActionTriggers {
    SHUTDOWN = 'SHUTDOWN',
    STARTUP = 'STARTUP',
    OPEN_DOCUMENT = 'OPEN_DOCUMENT',
    OPEN_FOLDER = 'OPEN_FOLDER',
    OPEN_BROWSER = 'OPEN_BROWSER',
    OPEN_SETTINGS = 'OPEN_SETTINGS',
    OPEN_MESSENGER = 'OPEN_MESSENGER',
    OPEN_RUN = 'OPEN_RUN',
    OPEN_GENERIC_MODAL = 'OPEN_GENERIC_MODAL'
}